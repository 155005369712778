let home = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/home.vue"),
    meta: {
      title: "首页",
      hiddenTitle: true,
      noback: true,
      guest: true,
    },
  },
  {
    path: "/functions",
    name: "functions",
    component: () => import("@/views/home/functions.vue"),
    meta: {
      title: "测试",
      noback: true,
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/home/search.vue"),
    meta: {
      title: "搜索",
      noback: true,
      guest: true,
    },
  },
  {
    path: "/article",
    name: "article",
    component: () => import("@/views/home/article.vue"),
    meta: {
      title: "文章",
    },
  },
  {
    path: "/learningCenter",
    name: "learningCenter",
    component: () => import("@/views/learningCenter/index.vue"),
    meta: {
      title: "学习中心",
      hiddenTitle: true,
      noback: true,
      // guest: {
      //   showBar: true,
      //   bottom: 50
      // }
    },
  },
  {
    path: "/ranking",
    name: "ranking",
    component: () => import("@/views/learningCenter/ranking.vue"),
    meta: {
      title: "学习排行榜",
    },
  },
  {
    path: "/redirect",
    name: "redirect",
    component: () => import("@/views/redirect/index.vue"),
    meta: {
      title: "禁止访问",
      filterTkPage: true,
      noback: true,
    },
  },
  {
    path: "/privacyAgree",
    name: "privacyAgree",
    component: () => import("@/views/agreement/privacy.vue"),
    meta: {
      title: "隐私政策",
      freeAccess: true,
      filterTkPage: true,
    },
  },
  {
    path: "/userAgree",
    name: "userAgree",
    component: () => import("@/views/agreement/user.vue"),
    meta: {
      title: "使用协议",
      freeAccess: true,
      filterTkPage: true,
    },
  },
  {
    path: "/courseService",
    name: "courseService",
    component: () => import("@/views/agreement/courseService.vue"),
    meta: {
      title: "用户课程服务协议",
      freeAccess: true,
      filterTkPage: true,
    },
  },
  {
    path: "/signIndex",
    name: "signIndex",
    component: () => import("@/views/sign/index.vue"),
    meta: {
      title: "完善资料",
      freeAccess: true,
    },
  },
  {
    path: "/signDetail",
    name: "signDetail",
    component: () => import("@/views/sign/detail.vue"),
    meta: {
      title: "签到成功",
    },
  },
  {
    path: "/jump",
    name: "jump",
    component: () => import("@/views/jump.vue"),
    meta: {
      title: "红杏林",
      noback: true,
      guest: true,
    },
  },
];

export default home;
